import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser, isPlatformServer, Location, LocationStrategy} from '@angular/common';
import {EMPTY, Observable, ReplaySubject} from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {
  getCategoryById,
  getPages,
  getPathInfos,
  getProductById,
  getStoreFront
} from '../store/luneshop/selectors/lune-shop.selectors';
import {PathInfos} from '../model/path-infos';
import {DrupalNode} from '../model/drupal-node';
import {ResourceTypes} from '../ngrx-json-api/ngrx-json-api-definitions';
import {Components} from '../moon-shop/components';
import {makeStateKey, Meta, StateKey, Title, TransferState} from '@angular/platform-browser';
import {CLIENT_NAME, CLIENT_TITLE, STORE_FRONT} from '../providers/storeFront.providers';
import {WindowService} from '../window/window.service';
import {BottomSheetOptions} from '../window/window.model';
import {ComponentsIds} from '../services/templates.service';
import {PathService} from './path.service';
import {NgrxJsonApiService, Query, StoreResource} from 'ngrx-json-api';
import {NgrxJsonApiQueries} from '../ngrx-json-api/ngrx-json-queries';
import {ResourceToProductPipe} from '../pipes/resource-to-product.pipe';
import {ResourceToPathInfosPipe} from '../pipes/resource-to-path-infos.pipe';
import {addProductToMap, setPathInfos} from '../store/luneshop/actions/lune-shop.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {Product, Variant} from '../model/product';
import {AppConfigService} from '../config/app-config.service';
import {StoreFront} from '../model/store-front';
import {JsonLdService, NgxSeoMetaTagAttr, SeoSocialShareService} from 'ngx-seo';
import {Image} from '../model/image';
import {MinMaxPrice, ProductMinMaxPricePipe} from '../pipes/product-min-max-price.pipe';
import { WINDOW } from '@ng-web-apis/common';

export const mainLandingTarget = 'main';


export interface TemplatesTravelogue {

  [contentType: string]: TemplatePassport;

}

export interface TemplatePassport {

  componentId?: string;
  landingTargetId?: string;
  isOverlay?: boolean;

}

export interface DrupalRouterQueryParams {

  [name: string]: any;

}

@Injectable(
  {providedIn: 'root'}
)
export class DrupalRouterService {

  public urlChanged$: ReplaySubject<{ url: string, state: any }> = new ReplaySubject<{ url: string, state: any }>(1);

  public pathInfos$: Observable<PathInfos>;

  public content$: ReplaySubject<DrupalNode> = new ReplaySubject<DrupalNode>(1);

  public currentTemplate$: ReplaySubject<TemplatePassport> = new ReplaySubject<TemplatePassport>(1);

  public overlayTemplate$: Observable<TemplatePassport>;

  public queryParams$: ReplaySubject<DrupalRouterQueryParams> = new ReplaySubject<DrupalRouterQueryParams>(1);

  public initContent$: ReplaySubject<DrupalNode> = new ReplaySubject<DrupalNode>(1);

  private routedContentStateKey: StateKey<any> = makeStateKey<any>('routedContentStateKey');

  private currentTemplateStateKey: StateKey<TemplatePassport> = makeStateKey<TemplatePassport>('currentTemplateStateKey');

  public url$: Observable<string> = this.urlChanged$.pipe(
    map(urlInfos => urlInfos?.url)
  );

  templatesTravelogue: TemplatesTravelogue = {
    [ResourceTypes.storeSection]: {
      componentId: Components.homeCatalog,
      landingTargetId: mainLandingTarget
    },
    [ResourceTypes.storeFront]: {
      componentId: Components.homeCatalog,
      landingTargetId: mainLandingTarget
    },
    [ResourceTypes.commerceProductDefault]: {
      componentId: Components.productDetail,
      landingTargetId: mainLandingTarget
    },
    [ResourceTypes.storePage]: {
      isOverlay: true
    }
  };

  history: DrupalNode[] = [];

  canonicalUrl: string;

  constructor(
    private location: Location,
    private locationStrategy: LocationStrategy,
    private store: Store<any>,
    private titleService: Title,
    private metaService: Meta,
    @Inject(CLIENT_TITLE) private readonly clientTitle$: Observable<string>,
    @Inject(CLIENT_NAME) private readonly clientName$: Observable<string>,
    @Inject(STORE_FRONT) private readonly storeFront$: Observable<StoreFront>,
    private windowService: WindowService,
    private pathService: PathService,
    private ngrxJsonApiService: NgrxJsonApiService,
    private resourceToProductPipe: ResourceToProductPipe,
    private resourceToPathInfosPipe: ResourceToPathInfosPipe,
    private productMinMaxPricePipe: ProductMinMaxPricePipe,
    private appConfig: AppConfigService,
    private transferState: TransferState,
    private readonly seoSocialShareService: SeoSocialShareService,
    private readonly jsonLdService: JsonLdService,
    @Inject(WINDOW) protected window: Window,
    @Inject(PLATFORM_ID) private platformId: any
  ) {

  }


  public init() {

    if (isPlatformBrowser(this.platformId) && this.transferState.hasKey(this.currentTemplateStateKey)) {
      this.currentTemplate$.next(this.transferState.get(this.currentTemplateStateKey, null));
      this.transferState.remove(this.currentTemplateStateKey);
    } else {
      this.currentTemplate$.next(null);
    }

    this.url$.pipe(
      map(url => {
        const splitUrl: string[] = url.split('/');
        splitUrl.shift();
        splitUrl.shift();
        if (!/^(node|product)$/[Symbol.match](splitUrl[0])) {
          splitUrl.unshift(this.appConfig.getConfig().account);
        }
        return splitUrl.join('/');
      }),
      distinctUntilChanged(),
      switchMap(url => {
        if (url !== this.appConfig.getConfig().account) {
          return this.pathService.resolve(url);
        }
        return EMPTY;
      }),
      withLatestFrom(this.storeFront$),
      tap(([pathInfos, storeFront]) => {
        if (pathInfos instanceof HttpErrorResponse) {
          console.error('pathInfos is on error', pathInfos);
          // this.go(storeFront.path);
        }
      }),
      map(([pathInfos, storeFront]) => {
        return pathInfos;
      }),
      filter(pathInfos => pathInfos?.entity?.type === 'commerce_product'),
      switchMap(pathInfos => {
        const query: Query = {...NgrxJsonApiQueries.productsQueryBase};
        query.id = pathInfos.entity.uuid;
        query.queryId = 'initProduct';
        return this.ngrxJsonApiService.findOne({query, denormalise: true, fromServer: true})
          .pipe(
            filter(manyQueryResult => manyQueryResult?.loading === false && !!manyQueryResult?.data),
            map(manyQueryResult => manyQueryResult.data),
            take(1)
          );
      }),
      filter(resource => !!resource),
      switchMap((resource: StoreResource) => {
        return this.resourceToProductPipe.transform(resource);
      }),
      filter(product => !!product),
      take(1)
    ).subscribe((product: Product) => {
      if (product) {
        const pathInfos = this.resourceToPathInfosPipe.transform(product);
        this.store.dispatch(setPathInfos({pathInfos: [pathInfos]}));
        this.store.dispatch(addProductToMap({product}));
      }
    });

    // when first arriving, and navigating with back/forward in the app this.location.path()
    // does not contain the domain, but while navigating, we get it.
    // in order to simplify all the future parsing of the path we had a fake-domain to use the URL util

    this.manageUrl(this.window.location.href, this.location.getState());

    this.location.onUrlChange((url, state) => {
      this.manageUrl(url, state);
    });

    this.urlChanged$.pipe(
      distinctUntilChanged((oldValue, newValue) => {
        return oldValue?.url === newValue?.url;
      })
    );

    this.pathInfos$ = this.urlChanged$.pipe(
      map(urlInfos => urlInfos.url),
      switchMap(url => {
        return this.store.pipe(
          select(getPathInfos(url))
        );
      }),
      filter(pathInfos => !!pathInfos)
    );

    if (isPlatformBrowser(this.platformId)) {
      if (this.transferState.hasKey(this.routedContentStateKey)) {
        const routedContent: any = this.transferState.get(this.routedContentStateKey, null);
        if (routedContent) {
          this.content$.next(routedContent);
        }
        this.transferState.remove(this.routedContentStateKey);
      }
    }

    this.pathInfos$.pipe(
      distinctUntilKeyChanged('id'),
      switchMap(pathInfos => {
        let node$: Observable<DrupalNode> = EMPTY;
        if (pathInfos.type === ResourceTypes.storePage) {
          node$ = this.store.pipe(
            select(getPages),
            filter(pages => pages?.length > 0),
            map(pages => pages.filter(page => page.id === pathInfos.id)[0])
          );
        } else if (pathInfos.type === ResourceTypes.storeFront) {
          node$ = this.store.pipe(
            select(getStoreFront)
          );
        } else if (pathInfos.type === ResourceTypes.commerceProductDefault) {
          node$ = this.store.pipe(
            select(getProductById(pathInfos.id))
          );
        } else if (pathInfos.type === ResourceTypes.storeSection) {
          node$ = this.store.pipe(
            select(getCategoryById(pathInfos.id))
          );
        }
        return node$;
      }),
      shareReplay(1)
    ).subscribe(content => {
      if (content) {
        this.content$.next(content);
      }

    });

    this.content$.pipe(
      filter(content => !!content),
      distinctUntilKeyChanged('id'),
      withLatestFrom(this.clientName$, this.currentTemplate$)
    ).subscribe(([content, clientName, currentTemplate]) => {

        this.seoSocialShareService.setType('website');

        this.seoSocialShareService.setTitle(content.title + ' | ' + clientName);

        this.seoSocialShareService.setDescription(content.metaDescription ? content.metaDescription : '');


        this.canonicalUrl = this.locationStrategy.getBaseHref() + content.path;
        this.seoSocialShareService.setUrl(this.canonicalUrl);

        this.seoSocialShareService.setMetaTag({
          attr: NgxSeoMetaTagAttr.property, attrValue: 'og:site_name', value: clientName
        });

        this.seoSocialShareService.setMetaTag({
          attr: NgxSeoMetaTagAttr.property, attrValue: 'og:locale', value: 'fr_FR'
        });

        const image: Image = (content as Product)?.image || (content as StoreFront)?.welcomeImage;
        const imageUrl: string = image?.profiles?.style_w512?.href;

        if (imageUrl) {
          this.seoSocialShareService.setImage(imageUrl);
          this.seoSocialShareService.setTwitterCard('summary_large_image');
        } else {
          this.seoSocialShareService.setTwitterCard('summary');
        }

        if (content.type === ResourceTypes.productDefault) {

          const product: Product = content as Product;
          const productJsonLd: any = {
            name: product.title,
            url: this.canonicalUrl,
            image: [
              imageUrl
            ],
            description: content.metaDescription,
            brand: {
              '@type': 'Brand',
              name: clientName
            }
          };

          if (product.variants) {

            if (product.variants.length > 1) {

              const minMaxPrice: MinMaxPrice = this.productMinMaxPricePipe.transform(product);

              /*productJsonLd.offers = {
                '@type': 'AggregateOffer',
                lowPrice: minMaxPrice.min,
                highPrice: minMaxPrice.max,
                priceCurrency: minMaxPrice.currency
              };*/

              productJsonLd.offers = [];

              product.variants.forEach(variant => {
                productJsonLd.offers.push(
                  {
                    '@type': 'Offer',
                    name: variant.unit,
                    sku: variant.sku,
                    image: imageUrl,
                    price: variant.price.number.toFixed(2),
                    priceCurrency: variant.price.currency_code,
                    availability: variant.available ? 'InStock' : 'OutOfStock'
                  }
                );
              });
            } else if (product.variants.length === 1) {

              const variant: Variant = product.variants[0];
              if (variant) {
                productJsonLd.offers = {
                  '@type': 'Offer',
                  sku: variant.sku,
                  price: variant.price.number.toFixed(2),
                  name: variant.unit,
                  priceCurrency: variant.price.currency_code,
                  availability: variant.available ? 'InStock' : 'OutOfStock'
                };
              }

            }

          }

          const jsonLdObject = this.jsonLdService.getObject('Product', productJsonLd);
          this.jsonLdService.setData(jsonLdObject);
        }

        if (this.history.length === 0) {
          this.initContent$.next(content);
        } else {
          this.initContent$.next(null);
        }

        this.history.push(content);

        const templatePassport: TemplatePassport = this.templatesTravelogue[content.type];
        let template: any;
        if (templatePassport.isOverlay) {
          template = this.templatesTravelogue[ResourceTypes.storeFront];
        } else if (!templatePassport.isOverlay) {
          template = templatePassport;
        }

        if (isPlatformServer(this.platformId)) {
          this.transferState.set(this.currentTemplateStateKey, template);
        }

        this.currentTemplate$.next(template);

      }
    );

    this.overlayTemplate$ = this.content$.pipe(
      filter(content => !!content),
      map(content => {
        return this.templatesTravelogue[content.type];
      }),
      filter(template => template?.isOverlay === true)
    );

    this.overlayTemplate$.subscribe(overlayTemplate => {
      const options: BottomSheetOptions = {
        bottomSheetConfig: {
          panelClass: ['shop-utils-theme', 'information-bottom-sheet'],
          closeOnNavigation: false
        },
        passport: {
          components: {
            title: null,
            content: {
              component: ComponentsIds.informations
            },
            actions: null
          }
        },
        queryParamId: 'info',
        windowId: 'informationsBottomSheet'
      };
      options.passport.closePath = this.history.length > 1 ? this.history[this.history.length - 2].path : '/fr';
      this.windowService.openBottomSheet(options);
    });

  }

  manageUrl(url: string, state: any) {
    /*const urlInfos: URL = this.parseUrl(url);
    let pathname: string;
    if (urlInfos.pathname === '/') {
      pathname = '/fr';
      this.location.replaceState(pathname);
    } else {
      pathname = urlInfos.pathname;
    }
    const params: URLSearchParams = new URLSearchParams(urlInfos.search);

    const drupalRouterQueryParams: DrupalRouterQueryParams = {};
    for (const [key, value] of params as unknown as Iterable<any>) {
      drupalRouterQueryParams[key] = value;
    }
    this.queryParams$.next(drupalRouterQueryParams);
    this.urlChanged$.next({url: pathname, state});*/
  }

  parseUrl(url: string): URL {
    let urlInfos: URL;
    let path: string;
    if (url.indexOf('http') === -1) {
      path = 'http://www.fake-domain.com' + url;
    } else {
      path = url;
    }
    try {
      urlInfos = new URL(path);
    } catch (error) {
      // console.error('Here is the error message', error);
    }
    return urlInfos;
  }

  public go(path: string) {
    this.location.go(path);
  }

}
