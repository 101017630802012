import {APP_INITIALIZER, ErrorHandler, Injectable, InjectionToken, LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {
  APP_BASE_HREF,
  CommonModule,
  DOCUMENT,
  isPlatformServer,
  PlatformLocation,
  registerLocaleData
} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {HasNetworkInterceptor} from './has-network.interceptor';
import {CommerceCartTokenInterceptor} from './commerce-cart-token-interceptor';
import {environment} from '../environments/environment';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {NgrxJsonApiModule} from 'ngrx-json-api';
import {NgrxJsonApiDefinitions} from './ngrx-json-api/ngrx-json-api-definitions';
import {MAT_RIPPLE_GLOBAL_OPTIONS} from '@angular/material/core';
import {AppConfigModule} from './config/app-config.module';
import {LoadingModule} from './loading/loading.module';
import {WindowModule} from './window/window.module';
import {EmptyComponent} from './components/empty/empty.component';
import {STORE_FRONT_PROVIDERS} from './providers/storeFront.providers';
import {TranslocoRootModule} from './transloco/transloco-root.module';
import {configureScope, createErrorHandler} from '@sentry/angular';
import {CATALOG_PRODUCTS_PROVIDERS} from './providers/catalog-products.providers';
import {metaReducers, reducers} from './store';
import {StoreModule} from '@ngrx/store';
import {Components} from './moon-shop/components';
import {MilCoreModule} from './core/mil-core.module';
import {CoreUtilsModule} from './core/core-utils.module';
import {ROUTE_PROVIDERS} from './providers/route.providers';
import {OrderByPipe} from 'ngx-pipes';
import {CART_PROVIDERS} from './providers/cart.providers';
import {PATH_SERVICE_URL} from './drupal-router/drupal-router.providers';
import {APP_CONFIG_LOADER, AppConfig, AppConfigService, BrowserAppConfigLoader} from './config/app-config.service';
import {Observable, of} from 'rxjs';
import {PipesModule} from './pipes/pipes.module';
import {UI_PROVIDERS} from './providers/ui.providers';
import {Attributes, IntersectionObserverHooks, LAZYLOAD_IMAGE_HOOKS} from 'ng-lazyload-image';
import {debounceTime, distinctUntilChanged, map, shareReplay, tap} from 'rxjs/operators';
import {AnalyticsModule} from './analytics/analytics.module';
import {ALERTS_PROVIDERS} from './components/alerts/alerts.providers';
import {JsonLdModule} from 'ngx-seo';
import {TransferHttpCacheModule} from '@nguniversal/common';
import {BrowserStateInterceptor} from './browserstate.interceptor';
import {isScullyGenerated, isScullyRunning, ScullyLibModule} from '@scullyio/ng-lib';
import {CustomUrlSerializer} from './custom-url-serializer.service';
import {RouterModule, UrlSerializer} from '@angular/router';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {UrlGuard} from './url.guard';
import {NgrxJsonApiEffects} from './store/ngrx-json-api/ngrx-json-api.effects';
import {StoreUuidInterceptor} from './store-uuid.interceptor';
import {MEDIA_GALLERY_SERVER_SIDE_RENDERED} from './components/media-gallery/media-gallery.providers';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';

import localeFr from '@angular/common/locales/fr';
import {WithGmapModule} from "./components/with-gmap.directive";

registerLocaleData(localeFr);

@Injectable()
export class LazyLoadImageHooks extends IntersectionObserverHooks {

  getObservable(attributes: Attributes) {
    // Only load the image if it has been in the viewport for one second
    return super.getObservable(attributes).pipe(debounceTime(40));
  }

  skipLazyLoading(attributes: Attributes) {
    // Skipping lazyloading the image for SSG
    return isScullyRunning();
  }

  /*loadImage({imagePath}: Attributes): Promise<string> {
    console.log('loadImage', fetch);
    return fetch(imagePath)
      .then((res) => {
        console.log('res', res);
        return res.blob();
      })
      .then((blob) => {
        console.log('blob', blob);
        if (isPlatformBrowser(this.platformId)) {
          return URL.createObjectURL(blob);
        } else if (isPlatformServer(this.platformId)) {
          return imagePath;
        }
      });
  }*/

}

function loadConfigFactory(document: Document, appConfigService: AppConfigService) {
  // Easy as pie 🥧
  const body: HTMLElement = document.body;
  let scullyGeneratedAppConfig: AppConfig;
  if (isScullyGenerated()) {
    const backend = body.dataset.backend;
    const account = body.dataset.account;
    const devMode = body.dataset.devMode === 'true';
    scullyGeneratedAppConfig = {
      backend,
      account,
      devMode
    };
  }

  return (): Observable<AppConfig> => appConfigService.loadAppConfig(scullyGeneratedAppConfig).pipe(
    tap(
      (config: AppConfig) => {
        if (isScullyRunning()) {
          body.dataset.backend = config.backend;
          body.dataset.account = config.account;
          body.dataset.devMode = config.devMode === true ? 'true' : 'false';
        }
        configureScope((scope) => {
          scope.setTags({
            backend: config.backend,
            store_account: config.account,
            devMode: !!config.devMode ? 'true' : 'false',
          });
        });
      }
    )
  );
}

export const IS_SERVER_SIDE_RENDERING = new InjectionToken<boolean>(
  'IS_SERVER_SIDE_RENDERING'
);

@NgModule({
  declarations: [
    AppComponent,
    EmptyComponent
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    TransferHttpCacheModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'fr'
        },
        {
          path: '',
          loadChildren: () => import('./moon-shop/moon-shop.component').then(m => m.MoonShopModule)
        },
        {
          path: '**',
          component: EmptyComponent,
          canActivate: [UrlGuard]
        }
      ],
      {
        onSameUrlNavigation: 'reload',
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'disabled',
        scrollOffset: [0, 60]
      }
    ),
    HttpClientModule,
    HttpClientJsonpModule,
    AppConfigModule,
    CoreUtilsModule,
    MilCoreModule.forRoot({
      componentCreationFunctions: {
        [Components.moonshop]: createMoonshop,
        [Components.homeCatalog]: createHomeCatalog,
        [Components.productDetail]: createProductDetail,
        [Components.categoryProductList]: createCategoryProductList,
        [Components.alerts]: createAlert
      }
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      }
    }),
    EffectsModule.forRoot([
      NgrxJsonApiEffects
    ]),
    NgrxJsonApiModule.configure({
      apiUrl: 'set later',
      resourceDefinitions: NgrxJsonApiDefinitions.resourceDefinitions
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    LoadingModule,
    WindowModule,
    TranslocoRootModule,
    PipesModule,
    AnalyticsModule,
    JsonLdModule,
    ScullyLibModule.forRoot(
      {
        useTransferState: true,
        alwaysMonitor: true
      }
    ),
    StoreRouterConnectingModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MatMomentDateModule,
    MatRadioModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatInputModule
  ],
  exports: [],
  providers: [
    {provide: LOCALE_ID, useValue: 'fr-FR'},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    UrlGuard,
    {provide: UrlSerializer, useClass: CustomUrlSerializer},
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfigFactory,
      deps: [DOCUMENT, AppConfigService],
      multi: true
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation]
    },
    {
      provide: PATH_SERVICE_URL,
      useFactory: pathServiceUrlFactory,
      deps: [AppConfigService]
    },
    (environment.production ? {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    } : []),
    {provide: HTTP_INTERCEPTORS, useClass: HasNetworkInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: CommerceCartTokenInterceptor, multi: true},
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: {disabled: true}},
    STORE_FRONT_PROVIDERS,
    CATALOG_PRODUCTS_PROVIDERS,
    ROUTE_PROVIDERS,
    CART_PROVIDERS,
    UI_PROVIDERS,
    ALERTS_PROVIDERS,
    OrderByPipe,
    {provide: APP_CONFIG_LOADER, useClass: BrowserAppConfigLoader},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StoreUuidInterceptor,
      multi: true
    },
    {
      provide: IS_SERVER_SIDE_RENDERING,
      useFactory: () => isScullyRunning()
    },
    {
      provide: MEDIA_GALLERY_SERVER_SIDE_RENDERED,
      deps: [IS_SERVER_SIDE_RENDERING],
      useFactory: (isServerSideRendering: boolean) => of(isServerSideRendering)
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}

export function pathServiceUrlFactory(
  appConfigService: AppConfigService
): Observable<string> {
  return appConfigService.appConfig$.pipe(
    map(config => {
      return config ? config.backend + '/api/path' : null;
    }),
    distinctUntilChanged(),
    shareReplay(),
  );
}


function createMoonshop() {

  return import('./moon-shop/moon-shop.component').then((module) => {
    return module.MoonShopComponent;
  });

}

function createHomeCatalog() {

  return import('./templates/home-catalog/home-catalog.component').then((module) => {
    return module.HomeCatalogComponent;
  });

}

export function createProductDetail() {

  return import('./components/product-overview/product-overview.component').then((module) => {
    return module.ProductOverviewComponent;
  });

}

function createCategoryProductList() {

  return import('./components/category-product-list/category-product-list.component').then((module) => {
    return module.CategoryProductListComponent;
  });

}

function createAlert() {

  return import('./components/alerts/alerts.component').then((module) => {
    return module.AlertsComponent;
  });

}
