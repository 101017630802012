// token to access a stream with the information you need
import {InjectionToken, PLATFORM_ID, Provider} from '@angular/core';
import {Observable, of} from 'rxjs';
import {distinctUntilChanged, map, shareReplay} from 'rxjs/operators';
import {ResizeService} from '../utils/size-detector/resize.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {isPlatformBrowser} from '@angular/common';

const caniuse = require('caniuse-api');
caniuse.setBrowserScope('> 5%, last 2 version');

export const BREAKPOINT = new InjectionToken<Observable<string>>(
  'A stream with the current breakpoint'
);

export const ENABLE_GRADIENT_SCROLL = new InjectionToken<Observable<boolean>>(
  'A stream to enable the gradient scroll'
);

export const IS_XS = new InjectionToken<boolean>(
  'A stream to know if the current breakpoint is XS'
);

export const IS_CHROME = new InjectionToken<boolean>(
  'A stream to know if the current browser is Chrome'
);

export const IS_CHROME_ANDROID = new InjectionToken<boolean>(
  'A stream to know if the current browser is Chrome on Android'
);

export const IS_FIREFOX = new InjectionToken<boolean>(
  'A stream to know if the current browser is Firefox'
);

export const IS_EDGE = new InjectionToken<boolean>(
  'A stream to know if the current browser is Edge'
);

export const IS_SAFARI = new InjectionToken<boolean>(
  'A stream to know if the current browser is Safari'
);

export const IS_IOS = new InjectionToken<boolean>(
  'IS_IOS'
);

export const IS_SAMSUNG_INTERNET = new InjectionToken<boolean>(
  'A stream to know if the current browser is Samsung Internet'
);

export const IS_ANDROID = new InjectionToken<boolean>(
  'A stream to know if the current OS is Android'
);

export const BROWSER_VERSION = new InjectionToken<boolean>(
  'A stream to know the browser number version'
);

export const CAPABLE_OVERFLOW_ANCHOR = new InjectionToken<boolean>(
  'A stream to know if the current browser can manage overflow-anchor'
);

export const UI_PROVIDERS: Provider[] = [
  {
    provide: BREAKPOINT,
    deps: [ResizeService],
    useFactory: breakpointFactory
  },
  {
    provide: ENABLE_GRADIENT_SCROLL,
    deps: [PLATFORM_ID],
    useFactory: enableGradientScrollFactory
  },
  {
    provide: IS_XS,
    deps: [BREAKPOINT],
    useFactory: isXsFactory
  },
  {
    provide: IS_CHROME,
    deps: [DeviceDetectorService],
    useFactory: isChromeFactory
  },
  {
    provide: IS_CHROME_ANDROID,
    deps: [IS_CHROME, IS_ANDROID],
    useFactory: isChromeAndroidFactory
  },
  {
    provide: IS_FIREFOX,
    deps: [DeviceDetectorService],
    useFactory: isFirefoxFactory
  },
  {
    provide: IS_EDGE,
    deps: [DeviceDetectorService],
    useFactory: isEdgeFactory
  },
  {
    provide: IS_SAFARI,
    deps: [DeviceDetectorService],
    useFactory: isSafariFactory
  },
  {
    provide: IS_IOS,
    deps: [DeviceDetectorService],
    useFactory: isIosFactory
  },
  {
    provide: IS_SAMSUNG_INTERNET,
    deps: [DeviceDetectorService],
    useFactory: isSamsungInternetFactory
  },
  {
    provide: IS_ANDROID,
    deps: [DeviceDetectorService],
    useFactory: isAndroidFactory
  },
  {
    provide: BROWSER_VERSION,
    deps: [DeviceDetectorService],
    useFactory: browserVersionFactory
  },
  {
    provide: CAPABLE_OVERFLOW_ANCHOR,
    deps: [BROWSER_VERSION, IS_CHROME, IS_CHROME_ANDROID, IS_FIREFOX, IS_EDGE, IS_SAFARI, IS_SAMSUNG_INTERNET],
    useFactory: overflowAnchorCapableFactory
  }
];

export function breakpointFactory(
  resizeService: ResizeService
): Observable<BreakpointState> {
  return resizeService.breakpointState$;
}

export function isXsFactory(
  breakpointState$: Observable<BreakpointState>
): Observable<boolean> {
  return breakpointState$.pipe(
    distinctUntilChanged(),
    map(breakpointState => breakpointState.breakpoints[Breakpoints.XSmall]),
    shareReplay(1)
  );
}

export function enableGradientScrollFactory(platformId): Observable<boolean> {
  if (isPlatformBrowser(platformId)) {
    const enableGradientScroll: boolean = localStorage && localStorage.getItem('enableGradientScroll') ? localStorage.getItem('enableGradientScroll') === '1' : false;
    return of({}).pipe(
      map(() => enableGradientScroll),
      shareReplay(1)
    );
  }
  return of(false);
}

export function isChromeFactory(
  deviceDetectorService: DeviceDetectorService
): boolean {
  return deviceDetectorService.browser === 'Chrome';
}

export function isChromeAndroidFactory(
  isChrome: boolean,
  isAndroid: boolean
): boolean {
  return isChrome && isAndroid;
}

export function isIosFactory(
  deviceDetectorService: DeviceDetectorService
): boolean {
  return deviceDetectorService.os === 'iOS';
}

export function isSafariFactory(
  deviceDetectorService: DeviceDetectorService
): boolean {
  return deviceDetectorService.browser === 'Safari';
}

export function isFirefoxFactory(
  deviceDetectorService: DeviceDetectorService
): boolean {
  return deviceDetectorService.browser === 'Firefox';
}

export function isEdgeFactory(
  deviceDetectorService: DeviceDetectorService
): boolean {
  return deviceDetectorService.browser === 'MS-Edge-Chromium';
}

export function isSamsungInternetFactory(
  deviceDetectorService: DeviceDetectorService
): boolean {
  return deviceDetectorService.browser === 'Samsung';
}

export function isAndroidFactory(
  deviceDetectorService: DeviceDetectorService
): boolean {
  return deviceDetectorService.os === 'Android';
}

export function browserVersionFactory(
  deviceDetectorService: DeviceDetectorService
): number {
  return parseInt(deviceDetectorService.browser_version.split('.').shift(), 10);
}

export function overflowAnchorCapableFactory(
  browserVersion: boolean,
  isChrome: boolean,
  isChromeAndroid: boolean,
  isFirefox: boolean,
  isEdge: boolean,
  isSafari: boolean,
  isSamsung: boolean,
): boolean {
  const support = caniuse.getSupport('overflow-anchor', true);
  /*try {
    const isSupported = caniuse.isSupported('overflow-anchor', 'safari 14');
    console.log('isSupported', isSupported);
    const getSupport = caniuse.getSupport('overflow-anchor', true);
    console.log('getSupport', getSupport);
  } catch (e) {
    console.error('e', e);
  }*/
  return true;
  /*return (isChrome && browserVersion >= support?.chrome?.y) ||
    (isChromeAndroid && browserVersion >= support?.and_chr?.y) ||
    (isFirefox && browserVersion >= support?.firefox?.y) ||
    (isEdge && browserVersion >= support?.edge?.y) ||
    (isSamsung && browserVersion >= support?.samsung?.y);*/
}
