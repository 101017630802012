// token to access a stream with the information you need
import {InjectionToken, Provider} from '@angular/core';
import {combineLatest, Observable, of, throwError} from 'rxjs';
import {catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, withLatestFrom} from 'rxjs/operators';
import {NgrxJsonApiService, StoreResource} from 'ngrx-json-api';
import {ResourceTypes} from '../ngrx-json-api/ngrx-json-api-definitions';
import {Category} from '../model/product-category';
import {select, Store} from '@ngrx/store';
import {getCatalogLoaded, getProductCategoriesLoadingQueue} from '../store/luneshop/selectors/lune-shop.selectors';
import {
  selectAllCategories,
  selectCategoryById,
  selectCategoryEntities,
  selectCategoryProductIds
} from '../store/catalog-category/catalog-category.reducer';
import {Dictionary} from '@ngrx/entity';
import {ResourceIdentifier} from '@madeinlune/ngrx-json-api/src/interfaces';
import {CURRENT_ROUTED_CONTENT, CURRENT_ROUTED_PRODUCT_ID, ROUTE_SINGLE_CATEGORY} from './route.providers';
import {Product} from '../model/product';
import {CURRENT_PRODUCTS_GROUP} from '../components/menus-navigator/menus-navigator.providers';
import {
  ProductsGroup,
  selectProductCategoryRefs,
  selectProductsEntities,
  selectProductsGroup
} from '../store/catalog-product/catalog-product.reducer';
import {STORE_FRONT} from './storeFront.providers';
import {StoreFront} from '../model/store-front';
import {Breadcrumbs, BREADCRUMBS} from '../components/breadcrumb/breadcrumb.providers';
import {DrupalNode} from '../model/drupal-node';

export interface CatalogProductsMap {
  [id: string]: StoreResource;
}

export const CATALOG_PRODUCTS_ARRAY = new InjectionToken<Observable<StoreResource[]>>(
  'A stream with Catalog Products as an Array'
);

export const CATALOG_PRODUCTS_MAP_BY_ID = new InjectionToken<Observable<CatalogProductsMap>>(
  'A stream with Catalog Products as a Map with Product id as key'
);

export const CATALOG_PRODUCTS_MAP_BY_SKU = new InjectionToken<Observable<CatalogProductsMap>>(
  'A stream with Catalog Products as a Map with Product sku as key'
);

export const CATEGORIES = new InjectionToken<Observable<Category[]>>(
  'A stream with Categories containing their Products'
);

export const CATEGORIES_DICTIONARY = new InjectionToken<Observable<Dictionary<Category>>>(
  'CATEGORIES_DICTIONARY'
);

export const PRODUCTS_DICTIONARY = new InjectionToken<Observable<Dictionary<Product>>>(
  'PRODUCTS_DICTIONARY'
);

export const GET_CATEGORIES_LOADING_QUEUE = new InjectionToken<Observable<Category[]>>(
  'A stream with Categories Queue Loading'
);

export const CATALOG_LOADED = new InjectionToken<Observable<boolean>>(
  'A stream with to know if the catalog is entirely loaded'
);

export const CURRENT_PRODUCT_DEFAULT_CATEGORY = new InjectionToken<Observable<Category>>(
  'CURRENT_PRODUCT_DEFAULT_CATEGORY'
);


export const CATALOG_PRODUCTS_PROVIDERS: Provider[] = [
  {
    provide: BREADCRUMBS,
    deps: [
      Store,
      STORE_FRONT,
      CURRENT_ROUTED_CONTENT,
      ROUTE_SINGLE_CATEGORY,
      CURRENT_PRODUCT_DEFAULT_CATEGORY
    ],
    useFactory: breadcrumbsFactory
  },
  {
    provide: CURRENT_PRODUCT_DEFAULT_CATEGORY,
    deps: [CURRENT_ROUTED_PRODUCT_ID, Store],
    useFactory: currentProductDefaultCategoryFactory
  },
  {
    provide: CURRENT_PRODUCTS_GROUP,
    deps: [
      Store,
      STORE_FRONT,
      CURRENT_PRODUCT_DEFAULT_CATEGORY
    ],
    useFactory: currentProductsGroupFactory
  },
  {
    provide: CATALOG_PRODUCTS_ARRAY,
    deps: [NgrxJsonApiService],
    useFactory: cartProductsArrayFactory
  },
  {
    provide: CATALOG_PRODUCTS_MAP_BY_ID,
    deps: [NgrxJsonApiService],
    useFactory: cartProductsMapByIdFactory
  },
  {
    provide: CATALOG_PRODUCTS_MAP_BY_SKU,
    deps: [NgrxJsonApiService],
    useFactory: cartProductsMapBySkuFactory
  },
  {
    provide: CATEGORIES,
    deps: [Store],
    useFactory: categoriesFactory
  },
  {
    provide: CATEGORIES_DICTIONARY,
    deps: [Store],
    useFactory: categoriesDictionaryFactory
  },
  {
    provide: PRODUCTS_DICTIONARY,
    deps: [Store],
    useFactory: productsDictionaryFactory
  },
  {
    provide: GET_CATEGORIES_LOADING_QUEUE,
    deps: [Store],
    useFactory: categoriesLoadingQueueFactory
  },
  {
    provide: CATALOG_LOADED,
    deps: [Store],
    useFactory: catalogLoadedFactory
  }
];

export function breadcrumbsFactory(
  store: Store<any>,
  storeFront$: Observable<StoreFront>,
  routedContent$: Observable<DrupalNode>,
  routeSingleCategory$: Observable<boolean>,
  defaultProductCategory$: Observable<Category>
): Observable<Breadcrumbs> {
  return combineLatest([routedContent$, defaultProductCategory$]).pipe(
    filter(([routedContent, defaultProductCategory]) => {
      return !!routedContent;
    }),
    withLatestFrom(
      storeFront$.pipe(filter(storeFront => !!storeFront)),
      routeSingleCategory$,
      store.pipe(select(selectProductsGroup))
    ),
    switchMap(([
                 [routedContent, defaultProductCategory],
                 storeFront,
                 routeSingleCategory,
                 currentProductsGroup
               ]) => {

      const breadcrumbs: Breadcrumbs = {
        breadcrumbs: [
          {
            title: 'Accueil',
            path: storeFront.ngPath,
            fragment: (routeSingleCategory) ? null : 'appWelcome'
          }
        ]
      };

      if (currentProductsGroup) {

        return store.pipe(
          select(selectCategoryById(defaultProductCategory?.parent?.id)),
          map(parentCategory => {
            if (parentCategory) {
              breadcrumbs.breadcrumbs.push(
                {
                  title: parentCategory.title,
                  path: (routeSingleCategory) ? parentCategory.ngPath : storeFront.ngPath,
                  fragment: (routeSingleCategory) ? null : parentCategory.fragment
                }
              );
            }
            breadcrumbs.breadcrumbs.push(
              {
                title: currentProductsGroup.title,
                path: (routeSingleCategory) ? currentProductsGroup.path : storeFront.ngPath,
                fragment: (routeSingleCategory) ? null : currentProductsGroup.fragment
              }
            );
            return breadcrumbs;
          })
        );

      } else if (defaultProductCategory) {
        if (defaultProductCategory.parent) {
          return productBreadcrumbsFactory(
            store,
            breadcrumbs,
            defaultProductCategory,
            routeSingleCategory,
            storeFront
          );
        } else {
          breadcrumbs.breadcrumbs.push(
            {
              title: defaultProductCategory.title,
              path: (routeSingleCategory) ? defaultProductCategory.ngPath : storeFront.ngPath,
              fragment: (routeSingleCategory) ? null : defaultProductCategory.fragment
            }
          );
        }
      }
      return of(breadcrumbs);
    }),
    catchError(error => {
      return throwError(error);
    })
  );

}

export function productBreadcrumbsFactory(
  store: Store<any>,
  breadcrumbs: Breadcrumbs,
  defaultProductCategory: Category,
  routeSingleCategory: boolean,
  storeFront: StoreFront
): Observable<Breadcrumbs> {
  return store.pipe(
    select(selectCategoryById(defaultProductCategory.parent.id)),
    map(parentCategory => {
      breadcrumbs.breadcrumbs.push(
        {
          title: parentCategory.title,
          path: (routeSingleCategory) ? parentCategory.ngPath : storeFront.ngPath,
          fragment: (routeSingleCategory) ? null : parentCategory.fragment
        }
      );
      breadcrumbs.breadcrumbs.push(
        {
          title: defaultProductCategory.title,
          path: (routeSingleCategory) ? defaultProductCategory.ngPath : storeFront.ngPath,
          fragment: (routeSingleCategory) ? null : defaultProductCategory.fragment
        }
      );
      return breadcrumbs;
    }),
    catchError(error => {
      return throwError(error);
    })
  );
}

export function currentProductsGroupFactory(
  store: Store<any>,
  storeFront$: Observable<StoreFront>,
  currentProductDefaultCategory$: Observable<Category>
): Observable<ProductsGroup> {
  const productsGroup$: Observable<ProductsGroup> = store.pipe(select(selectProductsGroup));
  return combineLatest([productsGroup$, currentProductDefaultCategory$]).pipe(
    withLatestFrom(storeFront$),
    switchMap(([[productsGroup, category], storeFront]) => {
      if (productsGroup) {
        return of(productsGroup);
      }
      if (category) {
        return store.pipe(
          select(selectCategoryProductIds(category.id)),
          map(products => {
            return {
              products,
              title: category.title,
              path: '/home/' + storeFront.id,
              fragment: category.fragment
            };
          })
        );
      }
      return of(null);
    }),
    catchError(error => {
      return throwError(error);
    })
  );
}

export function currentProductDefaultCategoryFactory(
  currentProductId$: Observable<string>,
  store: Store<any>
): Observable<Category> {
  return currentProductId$.pipe(
    distinctUntilChanged(),
    switchMap(currentProductId => {
      return store.pipe(
        select(selectProductCategoryRefs(currentProductId)),
        switchMap(categoryRefs => {
          if (Array.isArray(categoryRefs) && categoryRefs.length > 0) {
            const firstCategory: Category = categoryRefs[0];
            return store.pipe(
              select(selectCategoryById(firstCategory.id))
            );
          }
          return of(null);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
    }),
    catchError(error => {
      return throwError(error);
    })
  );
}

export function cartProductsArrayFactory(
  ngrxJsonApiService: NgrxJsonApiService
): Observable<StoreResource[]> {
  return ngrxJsonApiService.getDefaultZone()
    .selectStoreResourcesOfType(ResourceTypes.commerceProductVariationDefault)
    .pipe(
      map(products => {
        if (!!products) {
          return Object.keys(products).map(key => products[key]);
        } else {
          return [];
        }
      }),
      catchError(error => {
        return throwError(error);
      })
    );
}

export function categoriesFactory(
  store: Store<any>
): Observable<Category[]> {
  return store.pipe(
    select(selectAllCategories),
    map(categories => {
      return categories;
    }),
    shareReplay(1),
    catchError(error => {
      return throwError(error);
    })
  );
}

export function categoriesDictionaryFactory(
  store: Store<any>
): Observable<Dictionary<Category>> {
  return store.pipe(
    select(selectCategoryEntities),
    shareReplay(1),
    catchError(error => {
      return throwError(error);
    })
  );
}

export function productsDictionaryFactory(
  store: Store<any>
): Observable<Dictionary<Product>> {
  return store.pipe(
    select(selectProductsEntities),
    shareReplay(1),
    catchError(error => {
      return throwError(error);
    })
  );
}

export function cartProductsMapByIdFactory(
  ngrxJsonApiService: NgrxJsonApiService
): Observable<CatalogProductsMap> {
  return ngrxJsonApiService.getDefaultZone()
    .selectStoreResourcesOfType(ResourceTypes.commerceProductVariationDefault);
}

export function cartProductsMapBySkuFactory(
  ngrxJsonApiService: NgrxJsonApiService
): Observable<CatalogProductsMap> {
  return cartProductsArrayFactory(ngrxJsonApiService).pipe(
    map(products => {
      const catalogProductsMap: CatalogProductsMap = {};
      products.forEach(product => {
        catalogProductsMap[product?.attributes?.sku] = product;
      });
      return catalogProductsMap;
    }),
    catchError(error => {
      return throwError(error);
    })
  );
}

export function categoriesLoadingQueueFactory(
  store: Store<any>
): Observable<ResourceIdentifier[]> {
  return store.pipe(
    select(getProductCategoriesLoadingQueue),
    catchError(error => {
      return throwError(error);
    })
  );
}

export function catalogLoadedFactory(
  store: Store<any>
): Observable<boolean> {
  return store.pipe(
    select(getCatalogLoaded),
    catchError(error => {
      return throwError(error);
    })
  );
}
