import {Pipe, PipeTransform} from '@angular/core';
import {PathInfos} from '../model/path-infos';
import {DrupalNode} from '../model/drupal-node';

@Pipe({
  name: 'resourceToPathInfos'
})
export class ResourceToPathInfosPipe implements PipeTransform {

  transform(content: DrupalNode): PathInfos {

    const {title, metaDescription, path, language, id, type} = content;
    return {
      id,
      type,
      path,
      title,
      description: metaDescription,
      language
    };

  }

}
